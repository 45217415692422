<template>
  <div>

    <Table
         stripe :data="list" :columns="tableColumns" @on-selection-change="selectionChange"
        :height="clientHeight"
        :summary-method="handleSummary"
        show-summary></Table>
 <Row>
      <i-col span='12'>
        <span style="height: 28px;line-height: 28px;">已选中{{feeitemIds.length}}条</span>
        </i-col>
      <i-col  span='12'>
       <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-sizer
        :page-size-opts="[15,50,100,200,500]"
        :current="query.pageNumber"
        @on-change="handlePageChanged"
        @on-page-size-change="handlePageSizeChanged"></Page>
    </div>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule } from '@/utils/dateFormat'
// import { getContractPage } from '@/api/scp/contract'
import { listContractExecuteInfo } from '@/api/dw/verify'
import { formatReconcilliationStatus } from '@/utils/tagStatus'

export default {
  props: {
    params: Object
  },
  data () {
    return {
      clientHeight: 0,
      total: 0,

      query: {
        pageNumber: 1,
        pageSize: 15,
        companyId: 0,
        publisherId: 0,
        startDate: '',
        endDate: '',
        executeStatus: null,
        queryString: ''
      },
      list: [],
      tableColumns: [
        {
          type: 'selection',
          key: 'subjectId',
          width: 60,
          align: 'center'
        },
        {
          title: '代理商',
          key: 'companyName'
        },
        {
          title: '合同编号',
          key: 'code'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          render: (h, params) => {
            return h('div', [
              h('p', params.row.brandName || '-')
            ])
          }
        },
        {
          title: '合同起止日',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('span', this.formatSchuedle(params.row).value)])
          }
        },
        {
          title: '合同总额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '当前周期合同执行额',
          align: 'center',
          children: [
            {
              title: '媒介使用费',
              align: 'center',
              key: 'signUsePrice',
              render: (h, param) => {
                return h('span', {
                  class: 'money'
                }, toMoney(param.row.signUsePrice))
              }
            },
            {
              title: '制作费',
              align: 'center',
              key: 'producePrice',
              render: (h, param) => {
                return h('span', {
                  class: 'money'
                }, toMoney(param.row.producePrice))
              }
            },
            {
              title: '上刊费',
              align: 'center',
              key: 'installPrice',
              render: (h, param) => {
                return h('span', {
                  class: 'money'
                }, toMoney(param.row.installPrice))
              }
            }
          ]
        },
        {
          title: '状态',
          key: 'executeStatus',
          width: 120,
          render: (h, data) => {
            return formatReconcilliationStatus(
              h,
              data.row.executeStatus,
              data.row.executeStatus === '01' ? '部分对账' : (data.row.executeStatus === '1' ? '已对账' : '未对账')
            )
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$store.commit('set_reconciliationNew_pageType', 'transactionContractDetail')
                      this.$store.commit('set_reconciliationNew_contractId', params.row.subjectId)
                    }
                  }
                },
                '详情'
              )
            ])
          }
        }
      ],

      currentPageAllItemIds: [], // 当前操作的页面所有项的主键集合
      currentPageSelectionIdArray: []
    }
  },
  created () {
    this.$store.commit('set_reconciliationNew_notAllowReconciliationQuantity', 0)
    this.$store.commit('set_reconciliationNew_feeitemIds', null)
    this.query.publisherId = this.publisherId
    this.clientHeight = document.documentElement.clientHeight - 220
  },
  computed: {
    companyId () {
      return this.$store.state.reconciliationNew.companyId
    },
    publisherId () {
      return this.$store.state.reconciliationNew.publisherId
    },
    transactionReconciliationQuery () {
      return this.$store.state.reconciliationNew.transactionReconciliationQuery
    },
    transactionReconciliationPageNumber () {
      return this.$store.state.reconciliationNew.transactionReconciliationPageNumber
    },
    notAllowReconciliationQuantity () {
      return this.$store.state.reconciliationNew.notAllowReconciliationQuantity || 0
    },
    feeitemIds () {
      return this.$store.state.reconciliationNew.feeitemIds || []
    },
    beginUpdate () {
      return this.$store.state.reconciliationNew.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      this.$store.commit('set_reconciliationNew_notAllowReconciliationQuantity', 0)
      this.$store.commit('set_reconciliationNew_feeitemIds', null)
      if (this.transactionReconciliationQuery.companyId) {
        this.query.companyId = this.transactionReconciliationQuery.companyId
        this.query.startDate = this.transactionReconciliationQuery.startDate
        this.query.endDate = this.transactionReconciliationQuery.endDate
        this.query.queryString = this.transactionReconciliationQuery.queryString
        this.query.executeStatus = this.transactionReconciliationQuery.executeStatus === -1 ? null : this.transactionReconciliationQuery.executeStatus
      }
      this.query.pageNumber = this.transactionReconciliationPageNumber
      this.loadPage()
    }

  },
  methods: {
    reloadPage () {
      this.query.pageNumber = 1
      if (this.query.companyId) {
        this.loadPage()
      }
    },
    loadPage () {
      const that = this
      listContractExecuteInfo(that.query).then((res) => {
        if (res.list.length) {
          res.list.map((item) => {
            item.visible = item.visibleScope === 2
            item._checked = that.feeitemIds.includes(item.subjectId)
            return item
          })
          that.currentPageAllItemIds = res.list.map(item => {
            return item.subjectId
          })
        }

        that.list = res.list

        that.total = res.totalRow
      })
    },
    handleSummary ({ columns, data }) {
      const needSummarykey = ['amount', 'signUsePrice', 'producePrice', 'installPrice']
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    formatSchuedle (data) {
      return {
        value: GetCurrentSchedule(data.startDate, data.endDate),
        days: GetPublishDay(data.startDate, data.endDate)
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.$store.commit('set_reconciliationNew_transactionReconciliationPageNumber', page)
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.reloadPage()
    },
    selectionChange (selection) {
      const that = this
      var quantity = that.notAllowReconciliationQuantity
      const selectionIds = selection.map(item => {
        return item.subjectId
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach(item => {
        // 列是否是选中状态

        const currentItem = that.list.filter(x => x.subjectId === item)[0]
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.feeitemIds.includes(item)) {
            that.feeitemIds.push(item)
            if (currentItem.exeFlag === 0) {
              quantity = quantity + 1
            }
          }
        } else { // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.feeitemIds.includes(item)) {
            that.feeitemIds.splice(that.feeitemIds.indexOf(item), 1)
            if (currentItem.exeFlag === 0) {
              quantity = quantity - 1
            }
          }
        }
      })
      this.$store.commit('set_reconciliationNew_feeitemIds', that.feeitemIds)
      this.$store.commit('set_reconciliationNew_notAllowReconciliationQuantity', quantity)
    }
  }
}
</script>
